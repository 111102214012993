copyToClipboard = function (
  textToCopy,
  { element, tooltipDefault, tooltipOnSuccess, tooltipOnFailed }
) {
  navigator.clipboard.writeText(textToCopy).then(
    () => {
      /* clipboard successfully set */
      if (element) {
        const tooltip = bootstrap.Tooltip.getInstance(element); // Returns a Bootstrap tooltip instance

        if (tooltip) {
          tooltip.setContent({ ".tooltip-inner": tooltipOnSuccess });
          addEventListener("hidden.bs.tooltip", () => {
            tooltip.setContent({
              ".tooltip-inner": tooltipDefault,
            });
          });
        }
      }
    },
    () => {
      /* clipboard write failed */
      if (element) {
        const tooltip = bootstrap.Tooltip.getInstance(element); // Returns a Bootstrap tooltip instance

        if (tooltip) {
          tooltip.setContent({ ".tooltip-inner": tooltipOnFailed });
          addEventListener("hidden.bs.tooltip", () => {
            tooltip.setContent({
              ".tooltip-inner": tooltipDefault,
            });
          });
        }
      }
    }
  );
};
