// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "packs/products"
import "bootstrap"
import "packs/tooltips"
import "packs/copyToClipboard";
import "packs/search"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Bootstrap modals are closed by default. Certain modals however should be shown on page load.
// These are opened with the following method.
document.addEventListener("turbolinks:load", function() {
    const modalsToOpen = document.querySelectorAll('.modal-open-on-load')
    modalsToOpen.forEach(modal => {
        new bootstrap.Modal(modal).show()
    });
    const successToastElement = document.querySelector('.success-toast')
    if(successToastElement) {
        const successToast = bootstrap.Toast.getOrCreateInstance(successToastElement)
        successToast.show()
    }
})