document.addEventListener("turbolinks:load", function() {
  // Turbolinks related workaround: delete all existing tooltip elements from the view,
  // before initializing new ones.
  const TOOLTIP_ELEMENT_QUERY_SELECTOR = '.bs-tooltip-auto'
  const tooltipElementList = document.querySelectorAll(TOOLTIP_ELEMENT_QUERY_SELECTOR);

  Array.from(tooltipElementList).forEach(tooltipElement => tooltipElement.remove())

  // Initialize tooltips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );

  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
  );
})