window.disableDirectRegistrationSelect = function(that) {
  id_prefix = that.id.replace('_destroy', '')
  document.getElementById(id_prefix + 'registration_id').disabled = !that.checked;
  document.getElementById(id_prefix + 'device_type_id').disabled = !that.checked;
}

window.disableIndirectRegistrationSelect = function(that) {
  id_prefix = that.id.replace('_destroy', '')
  document.getElementById(id_prefix + 'register_id').disabled = !that.checked;
  document.getElementById(id_prefix + 'registration_number').disabled = !that.checked;
  document.getElementById(id_prefix + 'device_type_id').disabled = !that.checked;
}

window.toggleCountryAvailability = function(country, select) {
  if ('inactive' == select.selectedOptions[0].value) {
    document.getElementById(`country_area_${country}`).style.display = 'none';
  }
  else {
    document.getElementById(`country_area_${country}`).style.display = 'block';
  }
}

window.toggleBrandInputForRegister = function(register, select) {
  if ('figurative_mark' == select.selectedOptions[0].value) {
    $(`#brand_for_register_${register}`).find('input').val("");
    document.getElementById(`brand_for_register_${register}`).style.display = 'none';
  }
  if ('word_mark' == select.selectedOptions[0].value) {
    document.getElementById(`brand_for_register_${register}`).style.display = 'block';
  }
}
